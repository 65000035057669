<template>
  <path id="Tracé_578" data-name="Tracé 578" d="M45.213,64H28.76a.974.974,0,0,1-.975-.974V33a.974.974,0,0,1,.975-.973H50.7a.974.974,0,0,1,.973.973V63.025A.974.974,0,0,1,50.7,64Zm4.512-1.948V52.383H46.186v9.669Zm-5.485,0V58.19H40.7v3.861Zm-5.486,0V58.19H35.218v3.861Zm-9.023,0H33.27V58.19H29.732ZM44.24,56.244V52.383H40.7v3.861Zm-5.486,0V52.383H35.218v3.861Zm-5.485,0V52.383H29.732v3.861Zm16.455-5.808V46.575H46.186v3.861Zm-5.485,0V46.575H40.7v3.861Zm-5.486,0V46.575H35.218v3.861Zm-5.485,0V46.575H29.732v3.861Zm16.455-5.808V40.765H46.186v3.863Zm-5.485,0V40.765H40.7v3.863Zm-5.486,0V40.765H35.218v3.863Zm-5.485,0V40.765H29.732v3.863Zm16.455-5.809V33.974H29.732v4.845ZM.973,61.63A.973.973,0,0,1,0,60.656V5.157a.973.973,0,0,1,.973-.974H39.2a.974.974,0,0,1,.975.974v24.51a.974.974,0,0,1-1.948,0V6.13H1.947V59.684h22.81a.973.973,0,1,1,0,1.947ZM8.384,51.539a.973.973,0,1,1,0-1.947h7.865a.973.973,0,1,1,0,1.947Zm0-6.06a.973.973,0,1,1,0-1.947h15.5a.973.973,0,0,1,0,1.947Zm0-6.059a.973.973,0,1,1,0-1.947h15.5a.973.973,0,0,1,0,1.947Zm0-6.059a.973.973,0,1,1,0-1.947h15.5a.973.973,0,0,1,0,1.947Zm34.494-3.695V1.946H8.384A.973.973,0,1,1,8.384,0H43.852a.974.974,0,0,1,.973.973V29.667a.973.973,0,0,1-1.947,0ZM8.384,27.3a.973.973,0,1,1,0-1.947H34.245a.973.973,0,0,1,0,1.947Zm0-7.051a.973.973,0,1,1,0-1.947h6.837a.973.973,0,0,1,0,1.947Zm19.031-4.995a.973.973,0,1,1,0-1.947h6.831a.973.973,0,0,1,0,1.947Zm-19.031,0a.973.973,0,1,1,0-1.947h8.894a.973.973,0,1,1,0,1.947Z" transform="translate(0.5 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconCompteurPaie"
}
</script>

<style scoped>

</style>